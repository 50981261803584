import React, { useState } from "react";
import axiosInstance from "../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser, FaLock, FaKey } from "react-icons/fa";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    apiKey: "",
  });
  const [isChannelLoginStep2, setIsChannelLoginStep2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    setLoading(true);
    setError("");
    try {
      const adminResponse = await axiosInstance.post("auth/admin/login", {
        username: formData.username,
        password: formData.password,
      });

      if (adminResponse.data.role === "admin") {
        toast.success("Admin login successful");
        localStorage.setItem("token", adminResponse.data.token);
        localStorage.setItem("username", adminResponse.data.username);
        window.location.href = "/";
        return;
      }
    } catch {
      try {
        const channelResponse = await axiosInstance.post(
          "auth/channel/login/step1",
          {
            username: formData.username,
            password: formData.password,
          }
        );

        if (channelResponse.data.role === "channel") {
          setIsChannelLoginStep2(true);
          toast.info("Please enter your API Key");
        }
      } catch (err) {
        setError("Invalid credentials for admin or channel.");
        toast.error("Invalid credentials, please try again.");
      }
    }
    setLoading(false);
  };

  const handleApiKeySubmit = async () => {
    setLoading(true);
    setError("");
    try {
      const channelResponse = await axiosInstance.post(
        "auth/channel/login/step2",
        {
          username: formData.username,
          apiKey: formData.apiKey,
        }
      );

      toast.success("Channel login successful");
      localStorage.setItem("channelId", channelResponse.data.channelId);
      localStorage.setItem("username", channelResponse.data.username);
      window.location.href = `/channel-dashboard`;
    } catch (err) {
      setError("Invalid API Key.");
      toast.error("Invalid API Key, please check again.");
    }
    setLoading(false);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Login
        </h2>

        {error && <p className="text-red-600 text-center mb-4">{error}</p>}

        <div className="space-y-6">
          <label className="block relative">
            <span className="text-gray-700">Username</span>
            <div className="flex items-center mt-1">
              <FaUser className="absolute ml-3 text-gray-500" />
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                className="pl-10 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter your username"
              />
            </div>
          </label>

          <label className="block relative">
            <span className="text-gray-700">Password</span>
            <div className="flex items-center mt-1">
              <FaLock className="absolute ml-3 text-gray-500" />
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="pl-10 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter your password"
              />
            </div>
          </label>

          {isChannelLoginStep2 && (
            <label className="block relative">
              <span className="text-gray-700">API Key</span>
              <div className="flex items-center mt-1">
                <FaKey className="absolute ml-3 text-gray-500" />
                <input
                  type="text"
                  name="apiKey"
                  value={formData.apiKey}
                  onChange={handleInputChange}
                  className="pl-10 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                  placeholder="Enter your API Key"
                />
              </div>
            </label>
          )}

          <button
            onClick={isChannelLoginStep2 ? handleApiKeySubmit : handleLogin}
            className={`w-full py-3 rounded-md text-white font-bold ${
              loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 transition"
            }`}
            disabled={loading}
          >
            {loading
              ? "Processing..."
              : isChannelLoginStep2
              ? "Submit API Key"
              : "Login"}
          </button>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={3000} />
    </div>
  );
};

export default Login;
