import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";
import moment from "moment";

const ChannelDashboard = () => {
  const [channelData, setChannelData] = useState({
    name: "",
    apiKey: "",
    quota: 0,
    quotaUsed: 0,
  });
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const channelId = localStorage.getItem("channelId");

  useEffect(() => {
    const fetchChannelData = async () => {
      try {
        const response = await axiosInstance.get(`/api/channels/${channelId}`);
        setChannelData(response.data);
      } catch (err) {
        console.error("Error fetching channel data:", err);
      }
    };

    const fetchSubmissions = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/channel-submissions/${channelId}`
        );
        const sortedData = response.data.submissions.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setSubmissions(sortedData);
        setFilteredSubmissions(sortedData);
      } catch (err) {
        console.error("Error fetching submissions:", err);
      }
    };

    fetchChannelData();
    fetchSubmissions();
  }, [channelId]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = submissions.filter((submission) =>
      submission.device.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredSubmissions(filtered);
    setCurrentPage(1);
  };

  const paginate = (items, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize;
    return items.slice(startIndex, startIndex + pageSize);
  };

  const paginatedSubmissions = paginate(
    filteredSubmissions,
    currentPage,
    pageSize
  );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">
        Channel Dashboard
      </h2>

      {/* Quota and API Key Section */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-gray-700">
          <h3 className="text-xl font-semibold">Quota Usage</h3>
          <p className="text-3xl font-bold text-black">
            {channelData.quotaUsed} / {channelData.quota}
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-gray-700">
          <h3 className="text-xl font-semibold">API Key</h3>
          <p className="text-3xl font-mono bg-gray-100 p-4 rounded">
            {channelData.apiKey}
          </p>
        </div>
      </div>

      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search by device..."
          className="w-full p-2 border rounded"
        />
      </div>

      {/* Submissions Section */}
      <div className="bg-white shadow-md p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Submissions</h3>
        {paginatedSubmissions.length > 0 ? (
          <div>
            {/* Table View */}
            <table className="w-full border-collapse border border-gray-300 shadow-md rounded-lg">
              <thead className="bg-gradient-to-r from-gray-700 to-gray-800 text-white">
                <tr>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Device
                  </th>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Trade-In Value
                  </th>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Date
                  </th>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedSubmissions.map((submission) => (
                  <tr
                    key={submission._id}
                    className="border-t text-center hover:bg-gray-100 transition"
                  >
                    <td className="p-4">{submission.device}</td>
                    <td className="p-4">
                      Rs. {submission.tradeInValue.toLocaleString()}
                    </td>
                    <td className="p-4">
                      {moment(submission.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td className="p-4">
                      <button
                        onClick={() => setSelectedSubmission(submission)}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="bg-gradient-to-r from-gray-700 to-gray-800 text-white px-4 py-2 rounded-lg disabled:opacity-50"
              >
                Previous
              </button>
              <span>
                Page {currentPage} of{" "}
                {Math.ceil(filteredSubmissions.length / pageSize)}
              </span>
              <button
                onClick={() =>
                  setCurrentPage((prev) =>
                    Math.min(
                      prev + 1,
                      Math.ceil(filteredSubmissions.length / pageSize)
                    )
                  )
                }
                disabled={
                  currentPage ===
                  Math.ceil(filteredSubmissions.length / pageSize)
                }
                className="bg-blue-500 text-white px-4 py-2 rounded-lg disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <p className="text-gray-500">No submissions found.</p>
        )}
      </div>

      {/* Detailed View Section */}
      {selectedSubmission && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h3 className="text-lg font-semibold mb-4">
              Submission Details: {selectedSubmission.device}
            </h3>
            <ul className="list-disc pl-6">
              <li>
                <strong>Device:</strong> {selectedSubmission.device}
              </li>
              <li>
                <strong>IMEI:</strong> {selectedSubmission.options.imei}
              </li>
              <li>
                <strong>Base Price:</strong> Rs. {selectedSubmission.basePrice}
              </li>
              <li>
                <strong>Estimated Device Value:</strong> Rs.{" "}
                {selectedSubmission.tradeInValue.toLocaleString()}
              </li>
              <li>
                <strong>Trade-In Bonus:</strong> Rs.{" "}
                {selectedSubmission.tradeInBonus.toLocaleString() || 0}
              </li>
              <li>
                <strong>Estimated Trade-In Value:</strong> Rs.{" "}
                {selectedSubmission.finalTradeInValue.toLocaleString() || 0}
              </li>
              <li>
                <strong>Battery Health:</strong>{" "}
                {selectedSubmission.options.batteryHealth || "N/A"}
              </li>
              <li>
                <strong>Storage:</strong>{" "}
                {selectedSubmission.options.storageSize || "N/A"}
              </li>
              <li>
                <strong>Color:</strong>{" "}
                {selectedSubmission.options.color || "N/A"}
              </li>
              <li>
                <strong>SIM Variant:</strong>{" "}
                {selectedSubmission.options.simVariant || "N/A"}
              </li>
              <li>
                <strong>Functional:</strong>{" "}
                {selectedSubmission.options.isFunctional.includes("Yes")
                  ? "Yes"
                  : "No"}
              </li>
              {selectedSubmission.options.isFunctionalDetails?.length > 0 && (
                <li>
                  <strong>Functional Issues:</strong>{" "}
                  {selectedSubmission.options.isFunctionalDetails.join(", ")}
                </li>
              )}
              <li>
                <strong>Damaged:</strong>{" "}
                {selectedSubmission.options.isDamaged.includes("Yes")
                  ? "Yes"
                  : "No"}
              </li>
              {selectedSubmission.options.isDamagedDetails?.length > 0 && (
                <li>
                  <strong>Damage Details:</strong>{" "}
                  {selectedSubmission.options.isDamagedDetails.join(", ")}
                </li>
              )}
              <li>
                <strong>Repaired:</strong>{" "}
                {selectedSubmission.options.isRepaired.includes("Yes")
                  ? "Yes"
                  : "No"}
              </li>
              {selectedSubmission.options.isRepairedDetails?.length > 0 && (
                <li>
                  <strong>Repair Details:</strong>{" "}
                  {selectedSubmission.options.isRepairedDetails.join(", ")}
                </li>
              )}
              <li>
                <strong>Cosmetic Condition:</strong>
                <ul className="list-none pl-6">
                  <li>
                    <strong>Display:</strong>{" "}
                    {selectedSubmission.options.cosmeticCondition?.display ||
                      "N/A"}
                  </li>
                  <li>
                    <strong>Back:</strong>{" "}
                    {selectedSubmission.options.cosmeticCondition?.back ||
                      "N/A"}
                  </li>
                  <li>
                    <strong>Sides:</strong>{" "}
                    {selectedSubmission.options.cosmeticCondition?.sides ||
                      "N/A"}
                  </li>
                </ul>
              </li>
              <li>
                <strong>Factory Unlocked:</strong>{" "}
                {selectedSubmission.options.factoryUnlocked || "N/A"}
              </li>
              <li>
                <strong>Accessories:</strong>{" "}
                {selectedSubmission.options.accessories || "N/A"}
              </li>
              <li>
                <strong>Discount Code:</strong>{" "}
                {selectedSubmission.discountCode || "None"}
              </li>
              <li>
                <strong>Submission Date:</strong>{" "}
                {moment(selectedSubmission.createdAt).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </li>
            </ul>
            <button
              onClick={() => setSelectedSubmission(null)}
              className="bg-gray-500 text-white px-2 py-1 rounded mt-4"
            >
              Back to Table
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChannelDashboard;
