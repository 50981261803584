import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axiosInstance from "../services/api";

const ReorderDevices = () => {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axiosInstance.get("/api/devices");
        const sortedDevices = response.data.sort((a, b) => a.order - b.order);
        setDevices(sortedDevices);
      } catch (err) {
        console.error("Error fetching devices", err);
      }
    };

    fetchDevices();
  }, []);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedDevices = Array.from(devices);
    const [removed] = reorderedDevices.splice(result.source.index, 1);
    reorderedDevices.splice(result.destination.index, 0, removed);

    const updates = reorderedDevices.map((device, index) => ({
      _id: device._id,
      order: index,
    }));

    setDevices(reorderedDevices);

    try {
      await axiosInstance.patch("/api/update-order", { updates });
    } catch (err) {
      console.error("Error updating order", err);
    }
  };

  return (
    <div className="text-gray-700 p-6">
      <h2 className="text-2xl font-bold mb-6">Reorder Devices</h2>

      {/* Drag-and-Drop Context */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="devices">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="space-y-2"
            >
              {devices.map((device, index) => (
                <Draggable
                  key={device._id}
                  draggableId={device._id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="p-4 bg-white rounded shadow flex items-center justify-between"
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={device.deviceImageUrl}
                          alt={device.name}
                          className="w-16 h-16 object-cover rounded"
                        />
                        <div>
                          <h3 className="font-bold text-lg">{device.name}</h3>
                          <p className="text-sm text-gray-500">
                            {device.vendor}
                          </p>
                        </div>
                      </div>
                      <span className="text-gray-500">{device.year}</span>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ReorderDevices;
