import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";
import Devices from "./pages/Devices";
import Channels from "./pages/Channels";
import Deductions from "./pages/Deductions";
import ChannelDeductions from "./pages/channelDeductions";
import ReorderDevices from "./pages/ReorderDevices";
import Login from "./pages/Login";
import ChannelDashboard from "./pages/Channels-Dashboard";
import TradeInApiDocumentation from "./components/TradeInDocs";

// Role-based Access Control
const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  const channelId = localStorage.getItem("channelId");
  return token || channelId;
};

const getRole = () => {
  return localStorage.getItem("token") ? "admin" : "channel";
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(isAuthenticated());

  useEffect(() => {
    const handleStorageChange = () => {
      setAuthenticated(isAuthenticated());
    };

    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setAuthenticated(false);
  };

  return (
    <Router>
      <div className="flex">
        {authenticated && <Sidebar />}
        <div className="flex-1">
          {authenticated && <Header onLogout={handleLogout} />}
          <main className="p-4">
            <Routes>
              <Route path="/login" element={<Login />} />

              <Route
                path="/"
                element={
                  authenticated ? (
                    getRole() === "admin" ? (
                      <Dashboard />
                    ) : (
                      <Navigate to="/channel-dashboard" />
                    )
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/devices"
                element={
                  authenticated && getRole() === "admin" ? (
                    <Devices />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/channels"
                element={
                  authenticated && getRole() === "admin" ? (
                    <Channels />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/deductions"
                element={
                  authenticated && getRole() === "admin" ? (
                    <Deductions />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/channel-deductions"
                element={
                  authenticated && getRole() === "admin" ? (
                    <ChannelDeductions />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/reorder-devices"
                element={
                  authenticated && getRole() === "admin" ? (
                    <ReorderDevices />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/channel-dashboard"
                element={
                  authenticated && getRole() === "channel" ? (
                    <ChannelDashboard />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/trade-in-docs"
                element={<TradeInApiDocumentation />}
              />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
};

export default App;
