import React from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineDashboard, AiOutlineTeam } from "react-icons/ai";
import { MdDevices, MdReorder } from "react-icons/md";
import { FaBalanceScale } from "react-icons/fa";

const Sidebar = () => {
  const role = localStorage.getItem("token") ? "admin" : "channel";

  const adminNavItems = [
    { name: "Dashboard", icon: <AiOutlineDashboard />, path: "/" },
    { name: "Devices", icon: <MdDevices />, path: "/devices" },
    { name: "Channels", icon: <AiOutlineTeam />, path: "/channels" },
    { name: "Deductions", icon: <FaBalanceScale />, path: "/deductions" },
    {
      name: "Channel Deductions",
      icon: <FaBalanceScale />,
      path: "/channel-deductions",
    },
    {
      name: "Reorder Devices",
      icon: <MdReorder />,
      path: "/reorder-devices",
    },
  ];

  const channelNavItems = [
    { name: "Dashboard", icon: <AiOutlineDashboard />, path: "/" },
  ];

  const navItems = role === "admin" ? adminNavItems : channelNavItems;

  return (
    <div className="min-h-screen bg-gray-900 text-white w-64 flex flex-col shadow-xl">
      <div className="p-6 text-xl font-bold tracking-wide border-b border-gray-700">
        {role === "admin" ? "Admin Panel" : "Channel Panel"}
      </div>
      <nav className="flex flex-col space-y-2 p-4">
        {navItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            className={({ isActive }) =>
              `flex items-center space-x-3 p-3 rounded-lg transition-all duration-200 ${
                isActive ? "bg-gray-700" : "hover:bg-gray-800"
              }`
            }
          >
            <span className="text-xl">{item.icon}</span>
            <span className="text-lg font-medium">{item.name}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
