import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";

const Deductions = () => {
  const [devices, setDevices] = useState([]);
  const [channels, setChannels] = useState([]);
  const [deductions, setDeductions] = useState([]);

  const [formData, setFormData] = useState({
    deviceId: "",
    channelId: "",
    batteryHealthOptions: [],
    storageOptions: [],
    colorOptions: [],
    simOptions: [],
    isDamagedOptions: [],
    isFunctionalOptions: [],
    isRepairedOptions: [],
    cosmeticCondition: [],
    factoryUnlocked: 0,
    accessories: 0,
    tradeInBonus: 0,
  });

  const [batteryHealthFrom, setBatteryHealthForm] = useState({
    batteryHealth: "",
    deduction: 0,
  });
  const [storageForm, setStorageForm] = useState({ variant: "", deduction: 0 });
  const [colorForm, setColorForm] = useState({ color: "", deduction: 0 });
  const [simForm, setSimForm] = useState({ simVariant: "", deduction: 0 });
  const [newOption, setNewOption] = useState({
    type: "",
    option: "",
    description: "",
  });
  const [userDefinedOptions, setUserDefinedOptions] = useState({
    isDamagedOptions: [],
    isFunctionalOptions: [],
    isRepairedOptions: [],
  });

  const getMergedOptions = (type) => {
    return [
      ...(predefinedOptions[type] || []),
      ...(userDefinedOptions[type] || []),
    ];
  };

  const predefinedCosmeticConditions = {
    display: [
      {
        grade: "Excellent",
        deduction: 0,
        description: "1 - 2 hardly visible scratches or minimal signs of use",
      },
      {
        grade: "Good",
        deduction: 0,
        description: "Some visible signs of usage, but no deep scratches",
      },
      {
        grade: "Fair",
        deduction: 0,
        description: "Visible scratches, swirls, 1 - 2 minor deep scratches",
      },
    ],
    back: [
      {
        grade: "Excellent",
        deduction: 0,
        description: "1 - 2 hardly visible scratches or minimal signs of use",
      },
      {
        grade: "Good",
        deduction: 0,
        description: "Some visible signs of usage, but no deep scratches",
      },
      {
        grade: "Fair",
        deduction: 0,
        description: "Visible scratches, swirls, 1 - 2 minor deep scratches",
      },
    ],
    sides: [
      {
        grade: "Excellent",
        deduction: 0,
        description: "1 - 2 hardly visible scratches or minimal signs of use",
      },
      {
        grade: "Good",
        deduction: 0,
        description: "Some visible signs of usage, but no scuffs or dents",
      },
      {
        grade: "Fair",
        deduction: 0,
        description: "Visible scratches, swirls, 1 - 2 minor scuffs or dents",
      },
    ],
  };

  const predefinedOptions = {
    isDamagedOptions: [
      {
        option: "Damaged Display",
        description: "Front glass is cracked or shattered",
      },
      {
        option: "Damaged Back",
        description: "Back glass is cracked or shattered",
      },
      {
        option: "Damaged Camera Lens",
        description: "Camera lens is cracked or shattered",
      },
      {
        option: "Damaged Frame",
        description: "Phone body is cracked/bent or broken",
      },
    ],
    isFunctionalOptions: [
      { option: "Faulty Display", description: "Dead Pixels/Spots/Lines" },
      {
        option: "Faulty Earpiece",
        description: "No Audio/Noisy Audio during phone calls",
      },
      {
        option: "Faulty Face ID",
        description: "Face ID is not working or not working consistently",
      },
      {
        option: "Faulty Fingerprint Sensor",
        description:
          "Fingerprint sensor is not working or not working consistently",
      },
      {
        option: "Faulty Proximity Sensor",
        description: "Display remains on during calls",
      },
      {
        option: "Faulty Vibration Motor",
        description: "No Vibration/Rattling Noise",
      },
      {
        option: "Faulty Power Button",
        description: "Not Working/Hard to Press",
      },
      {
        option: "Faulty Volume Button",
        description: "Not Working/Hard to Press",
      },
      {
        option: "Faulty Mute Switch",
        description: "Not Working/Not Switching",
      },
      {
        option: "Faulty Front Camera",
        description: "Front Camera does not work, or the image is blurry",
      },
      {
        option: "Faulty Rear Camera",
        description: "Rear Camera does not work, or the image is blurry",
      },
      { option: "Faulty Flash", description: "Dead/Not Working" },
      { option: "Faulty Microphone", description: "Not Working/Noisy" },
      { option: "Faulty Loudspeaker", description: "No Audio/Noisy Audio" },
      { option: "Faulty Charging Port", description: "Dead/Not Working" },
    ],
    isRepairedOptions: [
      {
        option: "Touch Screen Replaced",
        description: "Touch Screen has been replaced",
      },
      {
        option: "Display Replaced",
        description: "Display has been replaced",
      },
      {
        option: "Front Camera Replaced",
        description: "Front Camera has been replaced",
      },
      {
        option: "Back Camera Replaced",
        description: "Back Camera has been replaced",
      },
      {
        option: "Loudspeaker Replaced",
        description: "Loudspeaker has been replaced",
      },
      {
        option: "Earpiece Replaced",
        description: "Earpiece has been replaced",
      },
      {
        option: "Microphone Replaced",
        description: "Microphone has been replaced",
      },
      {
        option: "Battery Replaced",
        description: "Battery has been replaced",
      },
      {
        option: "Motherboard Repaired",
        description: "Motherboard has been repaired",
      },
      {
        option: "Other Repairs",
        description: "Other types of repairs have been performed",
      },
    ],
  };

  const [selectedIsDamagedOptions, setSelectedIsDamagedOptions] = useState({});
  const [selectedIsFunctionalOptions, setSelectedIsFunctionalOptions] =
    useState({});
  const [selectedIsRepairedOptions, setSelectedIsRepairedOptions] = useState(
    {}
  );
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");

  // Fetch devices and channels on load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const devicesResponse = await axiosInstance.get("/api/devices");
        const channelsResponse = await axiosInstance.get("/api/channels");
        setDevices(devicesResponse.data);
        setChannels(channelsResponse.data);
      } catch (err) {
        console.error("Error fetching devices or channels:", err);
      }
    };
    fetchData();
  }, []);

  // Fetch deductions when device and channel are selected
  useEffect(() => {
    const fetchDeductions = async () => {
      if (selectedDevice && selectedChannel) {
        try {
          const response = await axiosInstance.get(
            `/api/deductions?deviceId=${selectedDevice}&channelId=${selectedChannel}`
          );
          console.log("Deductions API Response:", response.data);
          setDeductions(response.data);
        } catch (err) {
          console.error("Error fetching deductions:", err);
        }
      }
    };
    fetchDeductions();
  }, [selectedDevice, selectedChannel]);

  // Add options handlers

  const handleAddBatteryHealthOption = () => {
    if (batteryHealthFrom.batteryHealth && batteryHealthFrom.deduction >= 0) {
      setFormData((prev) => ({
        ...prev,
        batteryHealthOptions: [
          ...prev.batteryHealthOptions,
          { ...batteryHealthFrom },
        ],
      }));
      setBatteryHealthForm({ batteryHealth: "", deduction: 0 });
    }
  };

  const handleAddStorageOption = () => {
    if (storageForm.variant && storageForm.deduction >= 0) {
      setFormData((prev) => ({
        ...prev,
        storageOptions: [...prev.storageOptions, { ...storageForm }],
      }));
      setStorageForm({ variant: "", deduction: 0 });
    }
  };

  const handleAddColorOption = () => {
    if (colorForm.color && colorForm.deduction >= 0) {
      setFormData((prev) => ({
        ...prev,
        colorOptions: [...prev.colorOptions, { ...colorForm }],
      }));
      setColorForm({ color: "", deduction: 0 });
    }
  };

  const handleAddSimOption = () => {
    if (simForm.simVariant && simForm.deduction >= 0) {
      setFormData((prev) => ({
        ...prev,
        simOptions: [...prev.simOptions, { ...simForm }],
      }));
      setSimForm({ simVariant: "", deduction: 0 });
    }
  };

  const handleSaveDeductions = async () => {
    try {
      const response = await axiosInstance.post("/api/deductions", {
        ...formData,
        deviceId: selectedDevice,
        channelId: selectedChannel,
      });
      setDeductions([...deductions, response.data]);
      resetForm();
    } catch (err) {
      console.error("Error saving deductions:", err);
    }
  };

  const resetForm = () => {
    setFormData({
      deviceId: "",
      channelId: "",
      batteryHealthOptions: [],
      storageOptions: [],
      colorOptions: [],
      simOptions: [],
      isDamagedOptions: [],
      isFunctionalOptions: [],
      isRepairedOptions: [],
      cosmeticCondition: [],
      factoryUnlocked: 0,
      accessories: 0,
      tradeInBonus: 0,
    });
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold mb-6">Deductions Management</h2>
      {/* Device and Channel Selection */}
      <div className="mb-6">
        <label className="block mb-4">
          <span className="text-gray-700">Select Device</span>
          <select
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e.target.value)}
            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select Device</option>
            {devices.map((device) => (
              <option key={device._id} value={device._id}>
                {device.name}
              </option>
            ))}
          </select>
        </label>
        <label className="block">
          <span className="text-gray-700">Select Channel</span>
          <select
            value={selectedChannel}
            onChange={(e) => setSelectedChannel(e.target.value)}
            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select Channel</option>
            {channels.map((channel) => (
              <option key={channel._id} value={channel._id}>
                {channel.name}
              </option>
            ))}
          </select>
        </label>
      </div>

      {/* Bonus Percentage */}
      <div className="mb-6">
        <label className="block mb-2">
          <span className="text-gray-700">Bonus Percentage (%)</span>
          <input
            type="number"
            value={formData.tradeInBonus}
            onChange={(e) =>
              setFormData({ ...formData, tradeInBonus: e.target.value })
            }
            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
            placeholder="Enter bonus percentage"
          />
        </label>
      </div>

      {/* Battery Health Options */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Battery Health Options</h3>
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            placeholder="Battery Health"
            value={batteryHealthFrom.batteryHealth}
            onChange={(e) =>
              setBatteryHealthForm({
                ...batteryHealthFrom,
                batteryHealth: e.target.value,
              })
            }
            className="block w-full mb-4 border-gray-300 rounded-md shadow-sm"
          />
          <input
            type="number"
            placeholder="Deduction (%)"
            value={batteryHealthFrom.deduction}
            onChange={(e) =>
              setBatteryHealthForm({
                ...batteryHealthFrom,
                deduction: e.target.value,
              })
            }
            className="block w-full mb-4 border-gray-300 rounded-md shadow-sm"
          />
          <button
            onClick={handleAddBatteryHealthOption}
            className="bg-green-500 text-white px-4 py-2 rounded shadow col-span-2"
          >
            Add Battery Health Option
          </button>
        </div>

        {/* Display Added Battery Health Options */}
        {formData.batteryHealthOptions.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">
              Added Battery Health Options
            </h4>
            <ul className="list-disc pl-5">
              {formData.batteryHealthOptions.map((option, index) => (
                <li key={index} className="flex justify-between items-center">
                  <span>
                    {option.batteryHealth} - {option.deduction}%
                  </span>
                  <button
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        batteryHealthOptions: prev.batteryHealthOptions.filter(
                          (_, i) => i !== index
                        ),
                      }))
                    }
                    className="text-red-500"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Storage Options */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Storage Options</h3>
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            placeholder="Variant"
            value={storageForm.variant}
            onChange={(e) =>
              setStorageForm({ ...storageForm, variant: e.target.value })
            }
            className="block w-full mb-4 border-gray-300 rounded-md shadow-sm"
          />
          <input
            type="number"
            placeholder="Deduction (%)"
            value={storageForm.deduction}
            onChange={(e) =>
              setStorageForm({ ...storageForm, deduction: e.target.value })
            }
            className="block w-full mb-4 border-gray-300 rounded-md shadow-sm"
          />
          <button
            onClick={handleAddStorageOption}
            className="bg-blue-500 text-white px-4 py-2 rounded shadow col-span-2"
          >
            Add Storage Option
          </button>
        </div>

        {/* Display Added Storage Options */}
        {formData.storageOptions.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">
              Added Storage Options
            </h4>
            <ul className="list-disc pl-5">
              {formData.storageOptions.map((option, index) => (
                <li key={index} className="flex justify-between items-center">
                  <span>
                    {option.variant} - {option.deduction}%
                  </span>
                  <button
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        storageOptions: prev.storageOptions.filter(
                          (_, i) => i !== index
                        ),
                      }))
                    }
                    className="text-red-500"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/* Similar Structure for Color Options */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Color Options</h3>
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            placeholder="Color"
            value={colorForm.color}
            onChange={(e) =>
              setColorForm({ ...colorForm, color: e.target.value })
            }
            className="block w-full mb-4 border-gray-300 rounded-md shadow-sm"
          />
          <input
            type="number"
            placeholder="Deduction (%)"
            value={colorForm.deduction}
            onChange={(e) =>
              setColorForm({ ...colorForm, deduction: e.target.value })
            }
            className="block w-full mb-4 border-gray-300 rounded-md shadow-sm"
          />
          <button
            onClick={handleAddColorOption}
            className="bg-red-500 text-white px-4 py-2 rounded shadow col-span-2"
          >
            Add Color Option
          </button>
        </div>

        {/* Display Added Color Options */}
        {formData.colorOptions.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Added Color Options</h4>
            <ul className="list-disc pl-5">
              {formData.colorOptions.map((option, index) => (
                <li key={index} className="flex justify-between items-center">
                  <span>
                    {option.color} - {option.deduction}%
                  </span>
                  <button
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        colorOptions: prev.colorOptions.filter(
                          (_, i) => i !== index
                        ),
                      }))
                    }
                    className="text-red-500"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/* Similar Structure for SIM Options */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">SIM Options</h3>
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            placeholder="SIM Variant"
            value={simForm.simVariant}
            onChange={(e) =>
              setSimForm({ ...simForm, simVariant: e.target.value })
            }
            className="block w-full mb-4 border-gray-300 rounded-md shadow-sm"
          />
          <input
            type="number"
            placeholder="Deduction (%)"
            value={simForm.deduction}
            onChange={(e) =>
              setSimForm({ ...simForm, deduction: e.target.value })
            }
            className="block w-full mb-4 border-gray-300 rounded-md shadow-sm"
          />
          <button
            onClick={handleAddSimOption}
            className="bg-yellow-500 text-white px-4 py-2 rounded shadow col-span-2"
          >
            Add SIM Option
          </button>
        </div>

        {/* Display Added SIM Options */}
        {formData.simOptions.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Added SIM Options</h4>
            <ul className="list-disc pl-5">
              {formData.simOptions.map((option, index) => (
                <li key={index} className="flex justify-between items-center">
                  <span>
                    {option.simVariant} - {option.deduction}%
                  </span>
                  <button
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        simOptions: prev.simOptions.filter(
                          (_, i) => i !== index
                        ),
                      }))
                    }
                    className="text-red-500"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/* Add New Option */}

      <div className="mt-6">
        <h4 className="text-lg font-semibold">Add New Option</h4>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <select
            value={newOption.type}
            onChange={(e) =>
              setNewOption({ ...newOption, type: e.target.value })
            }
            className="block w-full border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select Type</option>
            <option value="isDamagedOptions">Damaged</option>
            <option value="isFunctionalOptions">Functional</option>
            <option value="isRepairedOptions">Repaired</option>
          </select>
          <input
            type="text"
            placeholder="Option Name"
            value={newOption.option}
            onChange={(e) =>
              setNewOption({ ...newOption, option: e.target.value })
            }
            className="block w-full border-gray-300 rounded-md shadow-sm"
          />
          <input
            type="text"
            placeholder="Description"
            value={newOption.description}
            onChange={(e) =>
              setNewOption({ ...newOption, description: e.target.value })
            }
            className="block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <button
          onClick={() => {
            if (
              newOption.type &&
              newOption.option.trim() &&
              newOption.description.trim()
            ) {
              setUserDefinedOptions((prev) => ({
                ...prev,
                [newOption.type]: [
                  ...(prev[newOption.type] || []),
                  {
                    option: newOption.option,
                    description: newOption.description,
                    deduction: 0,
                  },
                ],
              }));
              setNewOption({ type: "", option: "", description: "" });
            }
          }}
          className="bg-green-500 text-white px-4 py-2 rounded shadow"
        >
          Add New Option
        </button>
      </div>

      {/* Render IsDamagedOptions */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Damage Options</h3>
        {getMergedOptions("isDamagedOptions").map((option, index) => (
          <div key={index} className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={!!selectedIsDamagedOptions[option.option]}
                onChange={(e) => {
                  setSelectedIsDamagedOptions((prev) => ({
                    ...prev,
                    [option.option]: e.target.checked,
                  }));
                }}
                className="mr-2"
              />
              <span className="font-semibold">{option.option}</span>
            </label>
            <p className="text-gray-600 text-sm mb-2">{option.description}</p>
            {selectedIsDamagedOptions[option.option] && (
              <input
                type="number"
                placeholder="Deduction (%)"
                value={
                  formData.isDamagedOptions.find(
                    (opt) => opt.name === option.option
                  )?.deduction || ""
                }
                onChange={(e) => {
                  const updatedOptions = [...formData.isDamagedOptions];
                  const existingIndex = updatedOptions.findIndex(
                    (opt) => opt.name === option.option
                  );
                  if (existingIndex > -1) {
                    updatedOptions[existingIndex].deduction = e.target.value;
                  } else {
                    updatedOptions.push({
                      name: option.option,
                      description: option.description,
                      deduction: e.target.value,
                    });
                  }
                  setFormData({
                    ...formData,
                    isDamagedOptions: updatedOptions,
                  });
                }}
                className="block w-full border-gray-300 rounded-md shadow-sm"
              />
            )}
          </div>
        ))}
      </div>

      {/* Render IsFunctionalOptions */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Functional Options</h3>
        {getMergedOptions("isFunctionalOptions").map((option, index) => (
          <div key={index} className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={!!selectedIsFunctionalOptions[option.option]}
                onChange={(e) => {
                  setSelectedIsFunctionalOptions((prev) => ({
                    ...prev,
                    [option.option]: e.target.checked,
                  }));
                }}
                className="mr-2"
              />
              <span className="font-semibold">{option.option}</span>
            </label>
            <p className="text-gray-600 text-sm mb-2">{option.description}</p>
            {selectedIsFunctionalOptions[option.option] && (
              <input
                type="number"
                placeholder="Deduction (%)"
                value={
                  formData.isFunctionalOptions.find(
                    (opt) => opt.name === option.option
                  )?.deduction || ""
                }
                onChange={(e) => {
                  const updatedOptions = [...formData.isFunctionalOptions];
                  const existingIndex = updatedOptions.findIndex(
                    (opt) => opt.name === option.option
                  );
                  if (existingIndex > -1) {
                    updatedOptions[existingIndex].deduction = e.target.value;
                  } else {
                    updatedOptions.push({
                      name: option.option,
                      description: option.description,
                      deduction: e.target.value,
                    });
                  }
                  setFormData({
                    ...formData,
                    isFunctionalOptions: updatedOptions,
                  });
                }}
                className="block w-full border-gray-300 rounded-md shadow-sm"
              />
            )}
          </div>
        ))}
      </div>

      {/* Render IsRepairedOptions */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Repaired Options</h3>
        {getMergedOptions("isRepairedOptions").map((option, index) => (
          <div key={index} className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={!!selectedIsRepairedOptions[option.option]}
                onChange={(e) => {
                  setSelectedIsRepairedOptions((prev) => ({
                    ...prev,
                    [option.option]: e.target.checked,
                  }));
                }}
                className="mr-2"
              />
              <span className="font-semibold">{option.option}</span>
            </label>
            <p className="text-gray-600 text-sm mb-2">{option.description}</p>
            {selectedIsRepairedOptions[option.option] && (
              <input
                type="number"
                placeholder="Deduction (%)"
                value={
                  formData.isRepairedOptions.find(
                    (opt) => opt.name === option.option
                  )?.deduction || ""
                }
                onChange={(e) => {
                  const updatedOptions = [...formData.isRepairedOptions];
                  const existingIndex = updatedOptions.findIndex(
                    (opt) => opt.name === option.option
                  );
                  if (existingIndex > -1) {
                    updatedOptions[existingIndex].deduction = e.target.value;
                  } else {
                    updatedOptions.push({
                      name: option.option,
                      description: option.description,
                      deduction: e.target.value,
                    });
                  }
                  setFormData({
                    ...formData,
                    isRepairedOptions: updatedOptions,
                  });
                }}
                className="block w-full border-gray-300 rounded-md shadow-sm"
              />
            )}
          </div>
        ))}
      </div>

      {/* Cosmetic Conditions */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Cosmetic Conditions</h3>
        {Object.entries(predefinedCosmeticConditions).map(
          ([section, grades], sectionIndex) => (
            <div key={sectionIndex} className="mb-6">
              <h4 className="text-lg font-semibold mb-4">
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </h4>
              {grades.map((grade, gradeIndex) => (
                <div key={gradeIndex} className="mb-4">
                  <label className="block font-semibold">{grade.grade}</label>
                  <p className="text-gray-600 text-sm mb-2">
                    {grade.description}
                  </p>
                  <input
                    type="number"
                    placeholder="Deduction (%)"
                    value={
                      formData.cosmeticCondition
                        .find((cosmetic) => cosmetic.section === section)
                        ?.grades.find((g) => g.grade === grade.grade)
                        ?.deduction || ""
                    }
                    onChange={(e) => {
                      const updatedCosmeticConditions = [
                        ...formData.cosmeticCondition,
                      ];
                      let sectionIndex = updatedCosmeticConditions.findIndex(
                        (cosmetic) => cosmetic.section === section
                      );
                      if (sectionIndex === -1) {
                        // Add a new section if not already present
                        updatedCosmeticConditions.push({
                          section,
                          grades: [{ ...grade, deduction: e.target.value }],
                        });
                      } else {
                        const updatedGrades = [
                          ...updatedCosmeticConditions[sectionIndex].grades,
                        ];
                        const gradeIndex = updatedGrades.findIndex(
                          (g) => g.grade === grade.grade
                        );
                        if (gradeIndex === -1) {
                          updatedGrades.push({
                            ...grade,
                            deduction: e.target.value,
                          });
                        } else {
                          updatedGrades[gradeIndex].deduction = e.target.value;
                        }
                        updatedCosmeticConditions[sectionIndex].grades =
                          updatedGrades;
                      }
                      setFormData({
                        ...formData,
                        cosmeticCondition: updatedCosmeticConditions,
                      });
                    }}
                    className="block w-full border-gray-300 rounded-md shadow-sm"
                  />
                </div>
              ))}
            </div>
          )
        )}

        {/* Display Added Cosmetic Conditions */}
        {formData.cosmeticCondition.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">
              Added Cosmetic Conditions
            </h4>
            {formData.cosmeticCondition.map((cosmetic, index) => (
              <div key={index} className="mb-4">
                <h4 className="font-semibold">{cosmetic.section}</h4>
                <ul>
                  {cosmetic.grades.map((grade, i) => (
                    <li key={i}>
                      {grade.grade} - {grade.deduction}%
                      {grade.description && ` (${grade.description})`}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* PTA Approved, Factory Unlocked, Accessories */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <label>
          <span className="text-gray-700">
            NON-Factory Unlocked Deduction (%)
          </span>
          <input
            type="number"
            value={formData.factoryUnlocked}
            onChange={(e) =>
              setFormData({ ...formData, factoryUnlocked: e.target.value })
            }
            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
          />
        </label>
        <label>
          <span className="text-gray-700">NO-Accessories Deduction (%)</span>
          <input
            type="number"
            value={formData.accessories}
            onChange={(e) =>
              setFormData({ ...formData, accessories: e.target.value })
            }
            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
          />
        </label>
      </div>

      {/* Save and Display Deductions */}
      <button
        onClick={handleSaveDeductions}
        className="bg-blue-500 text-white px-4 py-2 rounded shadow"
      >
        Save Deductions
      </button>
    </div>
  );
};

export default Deductions;
