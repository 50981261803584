import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";

const Devices = () => {
  const [devices, setDevices] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    deviceType: "",
    vendor: "",
    basePrice: "",
    deviceImage: null,
    isManualName: false,
    year: "",
  });

  const [isEdit, setIsEdit] = useState(false);
  const [editDeviceId, setEditDeviceId] = useState(null);

  const [deviceNames, setDeviceNames] = useState([]);
  const [vendors, setVendors] = useState([]);
  const deviceTypes = ["Phone", "Tablet", "Laptop"];

  const deviceNameOptions = {
    Phone: {
      Apple: [
        "iPhone 16 Pro Max",
        "iPhone 16 Pro",
        "iPhone 16 Plus",
        "iPhone 16",
        "iPhone 15 Pro Max",
        "iPhone 15 Pro",
        "iPhone 15 Plus",
        "iPhone 15",
        "iPhone 14 Pro Max",
        "iPhone 14 Pro",
        "iPhone 14 Plus",
        "iPhone 14",
        "iPhone SE (3rd Gen)",
        "iPhone 13 Pro Max",
        "iPhone 13 Pro",
        "iPhone 13",
        "iPhone 13 Mini",
        "iPhone 12 Pro Max",
        "iPhone 12 Pro",
        "iPhone 12",
        "iPhone 12 Mini",
        "iPhone SE (2nd Gen)",
        "iPhone 11 Pro Max",
        "iPhone 11 Pro",
        "iPhone 11",
        "iPhone XS Max",
        "iPhone XS",
        "iPhone XR",
        "iPhone X",
      ],
      Samsung: [
        "Galaxy S24 Ultra",
        "Galaxy S24+",
        "Galaxy S24",
        "Galaxy S23 Ultra",
        "Galaxy S23+",
        "Galaxy S23",
        "Galaxy S22 Ultra",
        "Galaxy S22+",
        "Galaxy S22",
        "Galaxy S21 Ultra",
        "Galaxy S21+",
        "Galaxy S21",
        "Galaxy S20 Ultra",
        "Galaxy S20+",
        "Galaxy S20",
        "Galaxy Z Fold 6",
        "Galaxy Z Flip 6",
        "Galaxy Z Fold 5",
        "Galaxy Z Flip 5",
        "Galaxy Z Fold 4",
        "Galaxy Z Flip 4",
        "Galaxy Z Fold 3",
        "Galaxy Z Flip 3",
        "Galaxy A73",
        "Galaxy A72",
        "Galaxy A71",
        "Galaxy A55",
        "Galaxy A54",
        "Galaxy A53",
        "Galaxy A52",
        "Galaxy A51",
        "Galaxy A35",
        "Galaxy A34",
        "Galaxy A33",
        "Galaxy A32",
        "Galaxy A31",
        "Galaxy A30s",
        "Galaxy A25",
        "Galaxy A24",
        "Galaxy A23",
        "Galaxy A22",
        "Galaxy A21",
        "Galaxy A20",
        "Galaxy A16",
        "Galaxy A15",
        "Galaxy A14",
        "Galaxy A13",
        "Galaxy A12",
        "Galaxy A11",
        "Galaxy A10",
        "Galaxy 06",
        "Galaxy 05s",
      ],
      Xiaomi: [
        "Xiaomi 14 Ultra",
        "Xiaomi 14 T",
        "Xiaomi 14 Pro",
        "Xiaomi 14",
        "Xiaomi 13T Pro",
        "Xiaomi 12 Pro",
        "Xiaomi 12",
        "Redmi Note 13 PRO+ 5G",
        "Redmi Note 13 Pro",
        "Redmi 13",
        "Redmi Note 12S",
        "Redmi Note 12",
        "Redmi 12C",
        "Redmi Note 11 Pro+ 5G",
        "Redmi Note 11 Pro 5G",
        "Redmi Note 11 Pro",
        "Redmi Note 11",
        "Redmi Note 10 Pro",
        "Redmi Note 10 5G",
        "Redmi Note 10",
        "Redmi Note 10S",
        "Xiaomi POCO F6 Pro",
        "Xiaomi POCO F6",
        "Xiaomi POCO M6 Pro",
      ],
      Vivo: [
        "Vivo X90 Pro",
        "Vivo X90",
        "Vivo X80 Pro",
        "Vivo X80",
        "Vivo V27 Pro",
        "Vivo V27",
        "Vivo Y100",
        "Vivo Y75",
        "Vivo Y21",
      ],
      Oppo: [
        "Oppo Find X6 Pro",
        "Oppo Find X6",
        "Oppo Reno10 Pro+",
        "Oppo Reno10 Pro",
        "Oppo Reno10",
        "Oppo Reno8 Pro",
        "Oppo Reno8",
        "Oppo A98",
        "Oppo A78",
      ],
      Google: [
        "Pixel 8 Pro",
        "Pixel 8",
        "Pixel 7 Pro",
        "Pixel 7",
        "Pixel 6 Pro",
        "Pixel 6",
      ],
      Tecno: [
        "Tecno Camon 30 Pro 5G",
        "Tecno Camon 30s",
        "Tecno Camon 30",
        "Tecno Camon 20 Premier",
        "Tecno Camon 20 Premier",
        "Tecno Camon 20",
        "Tecno Camon 19 Pro Mondrain",
        "Tecno Camon 19 Pro",
        "Tecno Camon 19 Neo",
        "Tecno Camon 19",
        "Tecno Camon 18 Premier",
        "Tecno Camon 18 P",
        "Tecno Camon 18T",
        "Tecno Camon 17P",
        "Tecno Camon 17 Pro",
        "Tecno Camon 17",
        "Tecno Camon 16 Premier",
        "Tecno Camon 16 Pro",
        "Tecno Camon 16",
        "Tecno Camon 16SE",
        "Tecno Camon 15 Premier",
        "Tecno Camon 15 Pro",
        "Tecno Camon 15",
        "Tecno Spark 30 Pro",
        "Tecno Spark 30",
        "Tecno Spark 30C",
        "Tecno Spark 20 Pro Plus",
        "Tecno Spark 20 Pro",
        "Tecno Spark 20",
        "Tecno Spark 20C",
        "Tecno Spark 10 Pro",
        "Tecno Spark 10",
        "Tecno Spark 10C",
        "Tecno Spark 9 Pro",
        "Tecno Spark 9T",
        "Tecno Spark 9",
        "Tecno Spark 8",
        "Tecno Spark 8T",
        "Tecno Spark 8C",
        "Tecno Spark 7P",
        "Tecno Spark 7 Pro",
        "Tecno Spark 7",
        "Tecno Spark 7T",
        "Tecno Spark 6",
        "Tecno Spark 6 Air",
      ],
      Infinix: [
        "Infinix Zero 40",
        "Infinix Zero 30 5G",
        "Infinix Zero 30 4G",
        "Infinix Zero 20",
        "Infinix Zero X Pro",
        "Infinix Zero X Neo",
        "Infinix Zero 8",
        "Infinix Zero 8i",
        "Infinix Note 40 Pro Plus",
        "Infinix Note 40 Pro",
        "Infinix Note 40",
        "Infinix Note 30 VIP",
        "Infinix Note 30 Pro",
        "Infinix Note 30",
        "Infinix Note 30i",
        "Infinix Note 12 VIP",
        "Infinix Note 12 G96",
        "Infinix Note 12 G88",
        "Infinix Note 12",
        "Infinix Note 11 Pro",
        "Infinix Note 11s",
        "Infinix Note 11i",
        "Infinix Note 11",
        "Infinix Note 10 Pro",
        "Infinix Note 10",
        "Infinix Hot 50 Pro Plus",
        "Infinix Hot 50 Pro",
        "Infinix Hot 50",
        "Infinix Hot 50i",
        "Infinix Hot 40 Pro",
        "Infinix Hot 40",
        "Infinix Hot 40i",
        "Infinix Hot 30 5G",
        "Infinix Hot 30",
        "Infinix Hot 30i",
        "Infinix Hot 20s",
        "Infinix Hot 20i",
      ],
    },
    Tablet: {
      Apple: [
        "iPad Pro 12.9-inch",
        "iPad Pro 11-inch",
        "iPad Air",
        "iPad Mini",
        "iPad 10th Gen",
      ],
      Samsung: [
        "Galaxy Tab S9 Ultra",
        "Galaxy Tab S9+",
        "Galaxy Tab S9",
        "Galaxy Tab S8 Ultra",
        "Galaxy Tab S8+",
        "Galaxy Tab S8",
      ],
      Microsoft: ["Surface Pro 9", "Surface Pro 8", "Surface Go 3"],
    },
    Laptop: {
      Apple: [
        "MacBook Pro 16-inch",
        "MacBook Pro 14-inch",
        "MacBook Air M2",
        "MacBook Air M1",
      ],
      Dell: [
        "XPS 15",
        "XPS 13 Plus",
        "XPS 13",
        "Inspiron 16",
        "Inspiron 15",
        "Alienware m18",
        "Alienware x17 R2",
      ],
      HP: [
        "Spectre x360 16",
        "Spectre x360 14",
        "Pavilion Plus 14",
        "Pavilion x360 15",
        "Omen 16",
        "Envy x360 15",
      ],
      Lenovo: [
        "ThinkPad X1 Carbon",
        "ThinkPad X1 Yoga",
        "Yoga Slim 9i",
        "Legion Pro 7i",
        "IdeaPad Flex 5",
        "IdeaPad Gaming 3i",
      ],
      Asus: [
        "ROG Zephyrus G14",
        "ROG Strix Scar 16",
        "ZenBook Pro 14 Duo",
        "VivoBook S 15",
        "TUF Gaming A15",
      ],
    },
  };

  // Fetch devices on load
  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axiosInstance.get("/api/devices");
        setDevices(response.data);
      } catch (err) {
        console.error("Error fetching devices", err);
      }
    };

    fetchDevices();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Toggle between manual name input and dropdown
      setFormData({ ...formData, isManualName: checked, name: "" });
    } else if (name === "deviceType") {
      setFormData({
        ...formData,
        [name]: value,
        vendor: "",
        name: "",
        year: "",
      });
      setVendors(Object.keys(deviceNameOptions[value] || {}));
      setDeviceNames([]);
    } else if (name === "vendor") {
      setFormData({ ...formData, [name]: value, name: "" });
      setDeviceNames(deviceNameOptions[formData.deviceType]?.[value] || []);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // handle file change for image upload
  const handleFileChange = (e) => {
    setFormData({ ...formData, deviceImage: e.target.files[0] });
  };

  const resetForm = () => {
    setFormData({
      name: "",
      deviceType: "",
      vendor: "",
      basePrice: "",
      year: "",
      deviceImage: null,
      isManualName: false,
    });
    setIsEdit(false);
    setEditDeviceId(null);
    setVendors([]);
    setDeviceNames([]);
  };

  const handleAddOrEditDevice = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("deviceType", formData.deviceType);
    formDataToSend.append("vendor", formData.vendor);
    formDataToSend.append("basePrice", formData.basePrice);
    formDataToSend.append("year", formData.year);
    if (formData.deviceImage) {
      formDataToSend.append("deviceImage", formData.deviceImage);
    }

    try {
      if (isEdit) {
        // Edit existing device
        const response = await axiosInstance.patch(
          `/api/devices/${editDeviceId}`,
          formDataToSend,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        setDevices((prevDevices) =>
          prevDevices.map((device) =>
            device._id === editDeviceId ? response.data : device
          )
        );
      } else {
        // Add new device
        const response = await axiosInstance.post(
          "/api/devices",
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setDevices([...devices, response.data]);
      }
      resetForm();
    } catch (err) {
      console.error("Error saving device", err);
    }
  };

  const handleEditDevice = (device) => {
    const vendorsForType = Object.keys(
      deviceNameOptions[device.deviceType] || {}
    );
    const deviceNamesForVendor =
      deviceNameOptions[device.deviceType]?.[device.vendor] || [];

    setFormData({
      name: deviceNamesForVendor.includes(device.name) ? device.name : "",
      deviceType: device.deviceType,
      vendor: vendorsForType.includes(device.vendor) ? device.vendor : "",
      basePrice: device.basePrice,
      year: device.year,
      deviceImage: null, // Image needs to be selected manually for update
      isManualName: !deviceNamesForVendor.includes(device.name), // Set to true if the name is not in the predefined list
    });

    setIsEdit(true);
    setEditDeviceId(device._id);

    setVendors(vendorsForType);
    setDeviceNames(deviceNamesForVendor);
  };

  // Delete a device
  const handleDeleteDevice = async (id) => {
    try {
      await axiosInstance.delete(`/api/devices/${id}`);
      setDevices(devices.filter((device) => device._id !== id));
    } catch (err) {
      console.error("Error deleting device", err);
    }
  };

  return (
    <div className="text-gray-700">
      <h2 className="text-2xl font-bold mb-6">Devices Management</h2>

      {/* Form */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="grid grid-cols-1 gap-4">
          <label className="block">
            <span className="text-gray-700">Device Type</span>
            <select
              name="deviceType"
              value={formData.deviceType}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select Device Type</option>
              {deviceTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </label>

          <label className="block">
            <span className="text-gray-700">Vendor</span>
            <select
              name="vendor"
              value={formData.vendor}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              disabled={!vendors.length}
            >
              <option value="">Select Vendor</option>
              {vendors.map((vendor) => (
                <option key={vendor} value={vendor}>
                  {vendor}
                </option>
              ))}
            </select>
          </label>

          <label className="block">
            <span className="text-gray-700">Device Name</span>
            {!formData.isManualName ? (
              <select
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                disabled={!deviceNames.length}
              >
                <option value="">Select Device Name</option>
                {deviceNames.map((deviceName) => (
                  <option key={deviceName} value={deviceName}>
                    {deviceName}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter Device Name"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
            )}
          </label>

          <label className="block">
            <input
              type="checkbox"
              name="isManualName"
              checked={formData.isManualName}
              onChange={handleInputChange}
              className="mr-2"
            />
            <span className="text-gray-700">Manually Enter Device Name</span>
          </label>

          <label className="block">
            <span className="text-gray-700">Base Price</span>
            <input
              type="number"
              name="basePrice"
              placeholder="Enter Base Price"
              value={formData.basePrice}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Release Year</span>
            <input
              type="number"
              name="year"
              placeholder="Enter Release Year"
              value={formData.year}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Device Image</span>
            <input
              type="file"
              name="deviceImage"
              onChange={handleFileChange}
              accept="image/*"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <button
            onClick={handleAddOrEditDevice}
            className="bg-blue-500 text-white px-4 py-2 rounded shadow"
          >
            {isEdit ? "Update Device" : "Add Device"}
          </button>
          {isEdit && (
            <button
              onClick={resetForm}
              className="bg-gray-500 text-white px-4 py-2 rounded shadow mt-2"
            >
              Cancel Edit
            </button>
          )}
        </div>
      </div>

      {/* Devices Table */}
      <table className="w-full bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2">Name</th>
            <th className="p-2">Device Type</th>
            <th className="p-2">Vendor</th>
            <th className="p-2">Base Price</th>
            <th className="p-2">Year</th>
            <th className="p-2">Image</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device) => (
            <tr key={device._id} className="border-t">
              <td className="p-2">{device.name}</td>
              <td className="p-2">{device.deviceType}</td>
              <td className="p-2">{device.vendor}</td>
              <td className="p-2">PKR {device.basePrice.toLocaleString()}</td>
              <td className="p-2">{device.year}</td>
              <td className="p-2">
                {device.deviceImageUrl ? (
                  <img
                    src={device.deviceImageUrl}
                    alt={device.name}
                    className="w-16 h-16 rounded-md"
                  />
                ) : (
                  "No Image"
                )}
              </td>
              <td className="p-2">
                <button
                  onClick={() => handleEditDevice(device)}
                  className="bg-yellow-500 text-white px-2 py-1 rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteDevice(device._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Devices;
