import React from "react";

const Header = ({ onLogout }) => {
  const username = localStorage.getItem("username");
  const role = localStorage.getItem("token") ? "Admin" : "Channel";

  return (
    <header className="bg-gradient-to-r from-gray-800 to-gray-900 text-white p-4 flex justify-between items-center shadow-md">
      <h1 className="text-2xl font-bold tracking-wide">{role} Panel</h1>
      <div className="flex items-center space-x-6">
        <span className="text-lg font-medium">{username || "User"}</span>
        <button
          onClick={onLogout}
          className="px-4 py-2 bg-red-500 hover:bg-red-600 rounded-lg transition duration-200 shadow-md"
        >
          Logout
        </button>
      </div>
    </header>
  );
};

export default Header;
