import React from "react";

const TradeInApiDocumentation = () => {
  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        {/* Title Section */}
        <h1 className="text-4xl font-bold text-center mb-8">
          Trade-In API, Widget & Iframe Integration
        </h1>

        <p className="mb-6 text-gray-700 text-lg">
          The API supports integration via widgets or iframes, offering complete
          flexibility in embedding trade-in flows directly within your
          application. The trade-in system includes a configurable pricing
          engine, device management, and Shopify integration, allowing your
          customers to trade in their old devices for discounts, boosting sales
          and customer satisfaction.
        </p>

        <h2 className="text-3xl font-semibold mb-4">Key Features</h2>
        <ul className="list-disc ml-6 text-gray-700 text-lg mb-6">
          <li>
            <strong>Dynamic Device Management:</strong> Fetch and filter devices
            dynamically based on vendors and channels.
          </li>
          <li>
            <strong>Comprehensive Pricing Engine:</strong> Customizable
            deduction percentages for conditions like storage, repairs,
            functionality, and more.
          </li>
          <li>
            <strong>Multi-Channel Support:</strong> API keys ensure each channel
            can operate with its own devices and pricing setup.
          </li>
          <li>
            <strong>Real-Time Value Calculation:</strong> Instant evaluation of
            trade-in value based on selected options and conditions.
          </li>
          <li>
            <strong>Customizable UI:</strong> Choose between widget and iframe
            integration, with styling flexibility for a seamless user
            experience.
          </li>
          <li>
            <strong>Robust Security:</strong> Secure data handling with
            channel-specific API keys and sandbox support for iframes.
          </li>
          <li>
            <strong>Ready-to-Use Examples:</strong> Copy-and-paste code snippets
            for quick integration.
          </li>
        </ul>

        {/* Section 1: API Documentation */}
        <h2 className="text-3xl font-semibold mb-4">1. API Documentation</h2>
        <p className="mb-4">
          Below are the API endpoints offered by the platform. Please prepend
          the <strong>baseURL</strong> <code>https://api.getregen.co</code> to
          all endpoints. The documentation includes usage, payloads, responses,
          and error handling.
        </p>

        {/* Get Channel Devices */}
        <h3 className="text-2xl font-semibold mb-2">1.1 Get Channel Devices</h3>
        <p className="mb-2">
          Fetches devices available for the channel, filtered by allowed
          vendors.
        </p>
        <div className="mb-6">
          <p>
            <strong>Endpoint:</strong>{" "}
            <code>GET https://api.getregen.co/api/channel-devices</code>
          </p>
          <p>
            <strong>Headers:</strong>
          </p>
          <ul className="list-disc ml-6">
            <li>
              <code>x-api-key</code>: (string) API key specific to the channel.
            </li>
          </ul>
          <p>
            <strong>Response:</strong>
          </p>
          <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`[
  {
    "_id": "675ad18d0ebaa010c9dcd9cf",
    "name": "S23 Ultra",
    "deviceType": "Phone",
    "vendor": "Samsung",
    "basePrice": 216000
  }
]`}</pre>
          <p>
            <strong>Exceptional Cases:</strong>
          </p>
          <ul className="list-disc ml-6">
            <li>
              <strong>401 Unauthorized:</strong> Invalid or missing API key.
              <pre className="bg-red-100 p-4 rounded text-sm overflow-x-auto">{`{
  "error": "Unauthorized: Invalid API key"
}`}</pre>
            </li>
            <li>
              <strong>404 Not Found:</strong> No devices available for the
              channel.
              <pre className="bg-red-100 p-4 rounded text-sm overflow-x-auto">{`{
  "error": "No devices available for this channel"
}`}</pre>
            </li>
            <li>
              <strong>500 Server Error:</strong> Unexpected server-side issue.
              <pre className="bg-red-100 p-4 rounded text-sm overflow-x-auto">{`{
  "error": "Failed to fetch devices",
  "details": "Error message here"
}`}</pre>
            </li>
          </ul>
        </div>

        {/* Get Device Deductions */}
        <h3 className="text-2xl font-semibold mb-2">
          1.2 Get Device Deductions
        </h3>
        <p className="mb-2">
          Fetches pricing deductions for a specific device for the channel.
        </p>
        <div className="mb-6">
          <p>
            <strong>Endpoint:</strong>{" "}
            <code>
              GET https://api.getregen.co/api/device-deductions/:deviceId
            </code>
          </p>
          <p>
            <strong>Headers:</strong>
          </p>
          <ul className="list-disc ml-6">
            <li>
              <code>x-api-key</code>: (string) API key specific to the channel.
            </li>
          </ul>
          <p>
            <strong>Response:</strong>
          </p>
          <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`  {
    deviceId: "67743242790dda073a312c5e", // S23 Ultra
    channelId: "676a810f771a45dd0624578c",
    storageOptions: [
      {
        variant: "16GB/1TB",
        deduction: 0,
      },
      {
        variant: "12GB/512GB",
        deduction: 3,
      },
      {
        variant: "12/256GB",
        deduction: 5,
      },
    ],
    colorOptions: [
      {
        color: "Graphite",
        deduction: 0,
      },
      {
        color: "Green",
        deduction: 0,
      },
      {
        color: "Lavender",
        deduction: 0,
      },
      {
        color: "Cream",
        deduction: 0,
      },
      {
        color: "Lime",
        deduction: 0,
      },
      {
        color: "Sky Blue",
        deduction: 0,
      },
      {
        color: "Red",
        deduction: 0,
      },
      {
        color: "Phantom Black",
        deduction: 0,
      },
    ],
    simOptions: [
      {
        simVariant: "Dual eSIM",
        deduction: 10,
      },
      {
        simVariant: "eSIM + Physical",
        deduction: 0,
      },
      {
        simVariant: "Dual Physical",
        deduction: 0,
      },
    ],
    isDamagedOptions: [
      {
        name: "Damaged Display",
        description: "Front glass is cracked or shattered",
        deduction: 15,
      },
      {
        name: "Damaged Back",
        description: "Back glass is cracked or shattered",
        deduction: 15,
      },
      {
        name: "Damaged Camera Lens",
        description: "Camera lens is cracked or shattered",
        deduction: 15,
      },
      {
        name: "Damaged Frame",
        description: "Phone body is cracked/bent or broken",
        deduction: 25,
      },
    ],
    isFunctionalOptions: [
      {
        name: "Faulty Display",
        description: "Dead Pixels/Spots/Lines",
        deduction: 30,
      },
      {
        name: "Faulty Earpiece",
        description: "No Audio/Noisy Audio during phone calls",
        deduction: 10,
      },
      {
        name: "Faulty Face ID",
        description: "Face ID is not working or not working consistently",
        deduction: 20,
      },
      {
        name: "Faulty Proximity Sensor",
        description: "Display remains on during calls",
        deduction: 10,
      },
      {
        name: "Faulty Vibration Motor",
        description: "No Vibration/Rattling Noise",
        deduction: 10,
      },
      {
        name: "Faulty Power Button",
        description: "Not Working/Hard to Press",
        deduction: 10,
      },
      {
        name: "Faulty Volume Button",
        description: "Not Working/Hard to Press",
        deduction: 10,
      },
      {
        name: "Faulty Mute Switch",
        description: "Not Working/Not Switching",
        deduction: 10,
      },
      {
        name: "Faulty Front Camera",
        description: "Front Camera does not work, or the image is blurry",
        deduction: 15,
      },
      {
        name: "Faulty Rear Camera",
        description: "Rear Camera does not work, or the image is blurry",
        deduction: 20,
      },
      {
        name: "Faulty Flash",
        description: "Dead/Not Working",
        deduction: 15,
      },
      {
        name: "Faulty Microphone",
        description: "Not Working/Noisy",
        deduction: 10,
      },
      {
        name: "Faulty Loudspeaker",
        description: "No Audio/Noisy Audio",
        deduction: 10,
      },
      {
        name: "Faulty Charging Port",
        description: "Dead/Not Working",
        deduction: 15,
      },
    ],
    isRepairedOptions: [
      {
        name: "Touch Screen Replaced",
        description: "Touch Screen has been replaced",
        deduction: 15,
      },
      {
        name: "Display Replaced",
        description: "Display has been replaced",
        deduction: 25,
      },
      {
        name: "Front Camera Replaced",
        description: "Front Camera has been replaced",
        deduction: 10,
      },
      {
        name: "Back Camera Replaced",
        description: "Back Camera has been replaced",
        deduction: 15,
      },
      {
        name: "Loudspeaker Replaced",
        description: "Loudspeaker has been replaced",
        deduction: 10,
      },
      {
        name: "Earpiece Replaced",
        description: "Earpiece has been replaced",
        deduction: 10,
      },
      {
        name: "Microphone Replaced",
        description: "Microphone has been replaced",
        deduction: 10,
      },
      {
        name: "Battery Replaced",
        description: "Battery has been replaced",
        deduction: 10,
      },
      {
        name: "Battery Replaced By REGEN",
        description: "Battery replaced by REGEN",
        deduction: 0,
      },
      {
        name: "Motherboard Repaired",
        description: "Motherboard has been repaired",
        deduction: 25,
      },
      {
        name: "Other Repairs",
        description: "Other types of repairs have been performed",
        deduction: 10,
      },
    ],
    cosmeticCondition: [
      {
        section: "display",
        grades: [
          {
            grade: "Excellent",
            deduction: 0,
            description:
              "1 - 2 hardly visible scratches or minimal signs of use",
          },
          {
            grade: "Good",
            deduction: 5,
            description: "Some visible signs of usage, but no deep scratches",
          },
          {
            grade: "Fair",
            deduction: 7,
            description:
              "Visible scratches, swirls, 1 - 2 minor deep scratches",
          },
        ],
      },
      {
        section: "back",
        grades: [
          {
            grade: "Excellent",
            deduction: 0,
            description:
              "1 - 2 hardly visible scratches or minimal signs of use",
          },
          {
            grade: "Good",
            deduction: 4,
            description: "Some visible signs of usage, but no deep scratches",
          },
          {
            grade: "Fair",
            deduction: 6,
            description:
              "Visible scratches, swirls, 1 - 2 minor deep scratches",
          },
        ],
      },
      {
        section: "sides",
        grades: [
          {
            grade: "Excellent",
            deduction: 0,
            description:
              "1 - 2 hardly visible scratches or minimal signs of use",
          },
          {
            grade: "Good",
            deduction: 5,
            description: "Some visible signs of usage, but no scuffs or dents",
          },
          {
            grade: "Fair",
            deduction: 7,
            description:
              "Visible scratches, swirls, 1 - 2 minor scuffs or dents",
          },
        ],
      },
    ],
    ptaApproved: 34,
    factoryUnlocked: 18,
    accessories: 4,
  }`}</pre>
          <p>
            <strong>Exceptional Cases:</strong>
          </p>
          <ul className="list-disc ml-6">
            <li>
              <strong>401 Unauthorized:</strong> Invalid API key.
              <pre className="bg-red-100 p-4 rounded text-sm overflow-x-auto">{`{
  "error": "Unauthorized: Invalid API key"
}`}</pre>
            </li>
            <li>
              <strong>404 Not Found:</strong> No deductions for the device.
              <pre className="bg-red-100 p-4 rounded text-sm overflow-x-auto">{`{
  "error": "Deductions not found for this device"
}`}</pre>
            </li>
          </ul>
        </div>

        {/* Submit Trade-In Details */}
        <h3 className="text-2xl font-semibold mb-2">
          1.3 Submit Trade-In Details
        </h3>
        <p className="mb-2">
          Saves trade-in details and optionally generates a discount code for
          the transaction.
        </p>
        <div className="mb-6">
          <p>
            <strong>Endpoint:</strong>{" "}
            <code>POST https://api.getregen.co/api/trade-in-submit</code>
          </p>
          <p>
            <strong>Headers:</strong>
          </p>
          <ul className="list-disc ml-6">
            <li>
              <code>x-api-key</code>: (string) API key specific to the channel.
            </li>
          </ul>
          <p>
            <strong>Payload:</strong>
          </p>
          <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`{
  "device": "Samsung S23 Ultra",
  "basePrice": 216000,
  "tradeInValue": 49500,
  "options": {
    "storageSize": "12/512GB",
    "color": "Phantom Black",
    "simVariant": "eSIM + Physical",
    "isDamaged": "Damaged Display",
    "isFunctional": "Faulty Power Button",
    "isRepaired": "No",
    "cosmeticCondition": {
      "display": "Fair",
      "back": "Good",
      "sides": "Good"
    },
    "ptaApproved": "Yes",
    "factoryUnlocked": "null",  // Only Included if ptaApproved set to (No)
    "accessories": "Not Included"
  },
  "channelName": "YOUR_CHANNEL_NAME",
  "apiKey": "YOUR_API_KEY",
  "discountCode": "TRADEIN_XXXXX" // ONLY EXISTS IF DISCOUNT CODE IS GENERATED
}`}</pre>
          <p>
            <strong>Response:</strong>
          </p>
          <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`{
  "message": "Trade-in submitted successfully"
}`}</pre>
          <p>
            <strong>Exceptional Cases:</strong>
          </p>
          <ul className="list-disc ml-6">
            <li>
              <strong>401 Unauthorized:</strong> Invalid or missing API key.
              <pre className="bg-red-100 p-4 rounded text-sm overflow-x-auto">{`{
  "error": "Unauthorized: Invalid API key"
}`}</pre>
            </li>
            <li>
              <strong>500 Server Error:</strong> Unexpected server-side issue.
              <pre className="bg-red-100 p-4 rounded text-sm overflow-x-auto">{`{
  "error": "Trade-in submission failed",
  "details": "Error message here"
}`}</pre>
            </li>
          </ul>
        </div>

        {/* Widget Integration */}
        <h2 className="text-3xl font-semibold mb-4">2. Widget Integration</h2>
        <p className="mb-4">
          Embed and initialize the widget by copying the provided snippet into
          your HTML or JavaScript files.
        </p>

        {/* Embedding Widget */}
        <h3 className="text-2xl font-semibold mb-2">
          2.1 Embedding the Widget
        </h3>
        <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`<div id="trade-in-widget-container"></div>
<script src="https://client.getregen.co/widget/trade-in-widget.js"></script>`}</pre>

        {/* Widget Initialization */}
        <h3 className="text-2xl font-semibold mb-2">
          2.2 Initializing the Widget
        </h3>
        <p className="mb-4">
          Use one of the following cases to initialize the widget. Replace
          placeholders like `YOUR_API_KEY` with your actual values.
        </p>

        <h4 className="text-xl font-semibold mb-2">
          Case 1: Custom Trade-In Submissions
        </h4>
        <p className="mb-2 text-gray-700">
          This configuration is tailored for platforms that only need to record
          trade-in submissions without generating discounts or integrating with
          e-commerce platforms. It's perfect for standalone systems or custom
          solutions where you want full control over the trade-in data.
        </p>
        <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`window.TradeInWidget.initialize({
  containerId: "trade-in-widget-container",
  apiKey: "YOUR_API_KEY",
});`}</pre>

        <h4 className="text-xl font-semibold mb-2">
          Case 2: Display Discount Code
        </h4>
        <p className="mb-2 text-gray-700">
          This configuration is ideal for scenarios where you want to display
          the generated discount code to the user, giving them the option to
          copy and use it manually.
        </p>
        <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`window.TradeInWidget.initialize({
  containerId: "trade-in-widget-container",
  apiKey: "YOUR_API_KEY",
  shopifyConfig: {
    enableShopifyIntegration: true,
    autoApplyDiscount: false,
    shopifyCredentials: {
      storeUrl: "YOUR_SHOPIFY_STORE_URL",
      apiKey: "YOUR_SHOPIFY_API_KEY",
      accessToken: "YOUR_SHOPIFY_ACCESS_TOKEN"
    }
  },
  onDiscountGenerated: (discountCode) => {
    alert(\`Your discount code: \${discountCode}\`);
  }
});`}</pre>

        <h4 className="text-xl font-semibold mb-2">
          Case 3: Auto-Apply Discount
        </h4>
        <p className="mb-2 text-gray-700">
          This configuration is designed for use cases where you want to provide
          a seamless checkout experience by automatically applying the discount
          code after it is generated.
        </p>
        <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`window.TradeInWidget.initialize({
  containerId: "trade-in-widget-container",
  apiKey: "YOUR_API_KEY",
  shopifyConfig: {
    enableShopifyIntegration: true,
    autoApplyDiscount: true,
    shopifyCredentials: {
      storeUrl: "YOUR_SHOPIFY_STORE_URL",
      apiKey: "YOUR_SHOPIFY_API_KEY",
      accessToken: "YOUR_SHOPIFY_ACCESS_TOKEN"
    }
  },
  onDiscountGenerated: (discountCode) => {
    window.location.href = \`/checkout?discount=\${discountCode}\`;
  }
});`}</pre>

        {/* Iframe Integration */}
        <h2 className="text-3xl font-semibold mb-4">3. Iframe Integration</h2>
        <p className="mb-4">
          The iframe method embeds the trade-in flow in an iframe. Update the
          placeholders in the provided code snippets.
        </p>

        <h3 className="text-2xl font-semibold mb-2">
          3.1 Embedding the Iframe
        </h3>

        <h4 className="text-xl font-semibold mb-2">
          Case 1: Custom Trade-In Submissions
        </h4>
        <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`<iframe
  id="trade-in-widget-iframe"
  src="https://client.getregen.co/iframe/widget?apiKey=YOUR_API_KEY&shopifyConfig=%7B%22enableShopifyIntegration%22%3Afalse%7D"
  style="border: 1px solid black; width: 100%; height: 100vh;"
  allowfullscreen
  sandbox="allow-scripts allow-same-origin"
></iframe>`}</pre>

        <h4 className="text-xl font-semibold mb-2">
          Case 2: Display Discount Code
        </h4>
        <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`<iframe
  id="trade-in-widget-iframe"
  src="https://client.getregen.co/iframe/widget?apiKey=YOUR_API_KEY&shopifyConfig=%7B%22enableShopifyIntegration%22%3Atrue,%22autoApplyDiscount%22%3Afalse,%22shopifyCredentials%22%3A%7B%22storeUrl%22%3A%22YOUR_SHOPIFY_STORE_URL%22,%22apiKey%22%3A%22YOUR_SHOPIFY_API_KEY%22,%22accessToken%22%3A%22YOUR_SHOPIFY_ACCESS_TOKEN%22%7D%7D"
  style="border: 1px solid black; width: 100%; height: 100vh;"
  allowfullscreen
  sandbox="allow-scripts allow-same-origin"
></iframe>`}</pre>

        <h4 className="text-xl font-semibold mb-2">
          Case 3: Auto-Apply Discount
        </h4>
        <pre className="bg-gray-100 p-4 rounded text-sm overflow-x-auto">{`<iframe
  id="trade-in-widget-iframe"
  src="https://client.getregen.co/iframe/widget?apiKey=YOUR_API_KEY&shopifyConfig=%7B%22enableShopifyIntegration%22%3Atrue,%22autoApplyDiscount%22%3Atrue,%22shopifyCredentials%22%3A%7B%22storeUrl%22%3A%22YOUR_SHOPIFY_STORE_URL%22,%22apiKey%22%3A%22YOUR_SHOPIFY_API_KEY%22,%22accessToken%22%3A%22YOUR_SHOPIFY_ACCESS_TOKEN%22%7D%7D"
  style="border: 1px solid black; width: 100%; height: 100vh;"
  allowfullscreen
  sandbox="allow-scripts allow-same-origin"
></iframe>`}</pre>

        {/* Section 3: Contact */}
        <h2 className="text-3xl font-semibold mb-4">3. Contact</h2>
        <p>
          For assistance, contact:
          <br />
          <strong>Email:</strong>{" "}
          <a href="mailto:ali@regen.pk" className="text-blue-600 underline">
            ali@regen.pk
          </a>
        </p>
      </div>
    </div>
  );
};

export default TradeInApiDocumentation;
