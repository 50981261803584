import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";

const GetChannelDeductions = () => {
  const [channels, setChannels] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");
  const [deductions, setDeductions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [channelsRes, devicesRes] = await Promise.all([
          axiosInstance.get("/api/channels"),
          axiosInstance.get("/api/devices"),
        ]);
        setChannels(channelsRes.data);
        setDevices(devicesRes.data);
      } catch (err) {
        console.error("Error fetching devices or channels:", err);
      }
    };
    fetchOptions();
  }, []);

  // Fetch deductions when device and channel are selected
  const fetchDeductions = async () => {
    if (!selectedChannel || !selectedDevice) {
      setError("Please select both a channel and a device.");
      return;
    }
    setError("");
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/deductions?deviceId=${selectedDevice}&channelId=${selectedChannel}`
      );
      setDeductions(response.data);
    } catch (err) {
      console.error("Error fetching deductions:", err);
      setError("Failed to fetch deductions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDeductions = async () => {
    if (!deductions || !selectedChannel || !selectedDevice) {
      setError("Please fetch deductions before updating.");
      return;
    }
    setError("");
    setLoading(true);
    try {
      await axiosInstance.put(`/api/deductions/${deductions._id}`, {
        ...deductions,
      });
      alert("Deductions updated successfully.");
    } catch (err) {
      console.error("Error updating deductions:", err);
      setError("Failed to update deductions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Delete all deductions
  const handleDeleteAll = async () => {
    if (!selectedChannel || !selectedDevice) {
      setError("Please select both a channel and a device.");
      return;
    }
    setError("");
    setLoading(true);
    try {
      await axiosInstance.delete(
        `/api/deductions?deviceId=${selectedDevice}&channelId=${selectedChannel}`
      );
      setDeductions(null);
      alert("All deductions have been deleted successfully.");
    } catch (err) {
      console.error("Error deleting all deductions:", err);
      setError("Failed to delete all deductions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold mb-6">Get Channel Deductions</h2>

      {/* Channel Selection */}
      <div className="mb-6">
        <label className="block mb-2 text-gray-700">Select Channel</label>
        <select
          value={selectedChannel}
          onChange={(e) => setSelectedChannel(e.target.value)}
          className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
        >
          <option value="">Select Channel</option>
          {channels.map((channel) => (
            <option key={channel._id} value={channel._id}>
              {channel.name}
            </option>
          ))}
        </select>
      </div>

      {/* Device Selection */}
      <div className="mb-6">
        <label className="block mb-2 text-gray-700">Select Device</label>
        <select
          value={selectedDevice}
          onChange={(e) => setSelectedDevice(e.target.value)}
          className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
        >
          <option value="">Select Device</option>
          {devices.map((device) => (
            <option key={device._id} value={device._id}>
              {device.name}
            </option>
          ))}
        </select>
      </div>

      {/* Fetch and Delete Buttons */}
      <div className="flex space-x-4">
        <button
          onClick={fetchDeductions}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Fetch Deductions
        </button>
        <button
          onClick={handleDeleteAll}
          className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
        >
          Delete All Deductions
        </button>
        <button
          onClick={handleUpdateDeductions}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
          disabled={!deductions}
        >
          Update Deductions
        </button>
      </div>

      {/* Loading State */}
      {loading && <p className="text-gray-500 mt-4">Loading...</p>}

      {/* Error Message */}
      {error && <p className="text-red-500 mt-4">{error}</p>}

      {deductions && (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-4">Edit Deductions</h3>
          <div className="bg-white p-4 rounded-lg shadow">
            {Object.keys(deductions).map((key) => {
              if (Array.isArray(deductions[key])) {
                if (key === "cosmeticCondition") {
                  return (
                    <div key={key} className="mt-4">
                      <h4 className="font-bold capitalize">{key}</h4>
                      {deductions[key].map((section, sectionIndex) => (
                        <div key={sectionIndex} className="mt-2">
                          <h5 className="font-semibold capitalize">
                            Section: {section.section}
                          </h5>
                          {section.grades.map((grade, gradeIndex) => (
                            <div
                              key={gradeIndex}
                              className="flex items-center space-x-4 mt-2"
                            >
                              <input
                                type="text"
                                value={grade.grade}
                                readOnly
                                className="flex-1 border-gray-300 rounded-md shadow-sm p-2"
                              />
                              <input
                                type="number"
                                value={grade.deduction}
                                onChange={(e) => {
                                  const updatedDeductions = { ...deductions };
                                  updatedDeductions[key][sectionIndex].grades[
                                    gradeIndex
                                  ].deduction = parseInt(e.target.value, 10);
                                  setDeductions(updatedDeductions);
                                }}
                                className="w-20 border-gray-300 rounded-md shadow-sm p-2"
                              />
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <div key={key} className="mt-4">
                      <h4 className="font-bold capitalize">{key}</h4>
                      {deductions[key].map((item, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-4 mt-2"
                        >
                          <input
                            type="text"
                            value={
                              item.variant ||
                              item.color ||
                              item.simVariant ||
                              item.name ||
                              item.batteryHealth
                            }
                            readOnly
                            className="flex-1 border-gray-300 rounded-md shadow-sm p-2"
                          />
                          <input
                            type="number"
                            value={item.deduction}
                            onChange={(e) => {
                              const updatedDeductions = { ...deductions };
                              updatedDeductions[key][index].deduction =
                                parseInt(e.target.value, 10);
                              setDeductions(updatedDeductions);
                            }}
                            className="w-20 border-gray-300 rounded-md shadow-sm p-2"
                          />
                        </div>
                      ))}
                    </div>
                  );
                }
              } else if (
                ["factoryUnlocked", "accessories", "tradeInBonus"].includes(key)
              ) {
                return (
                  <div key={key} className="mt-4">
                    <h4 className="font-bold capitalize">{key}</h4>
                    <input
                      type="number"
                      value={deductions[key]}
                      onChange={(e) => {
                        const updatedDeductions = { ...deductions };
                        updatedDeductions[key] = parseInt(e.target.value, 10);
                        setDeductions(updatedDeductions);
                      }}
                      className="w-full border-gray-300 rounded-md shadow-sm p-2"
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default GetChannelDeductions;
