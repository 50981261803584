import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";
import moment from "moment";
// import { Bar, Pie } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   BarElement,
// } from "chart.js";

// ChartJS.register(
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   BarElement
// );

const Dashboard = () => {
  const [stats, setStats] = useState({
    totalDevices: 0,
    totalChannels: 0,
    totalSubmissions: 0,
  });
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  // const [submissionTrends, setSubmissionTrends] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [selectedSubmission, setSelectedSubmission] = useState(null);

  // Fetch stats and channels on load
  useEffect(() => {
    const fetchStatsAndChannels = async () => {
      try {
        const devices = await axiosInstance.get("/api/devices");
        const channelsResponse = await axiosInstance.get("/api/channels");
        const submissionsResponse = await axiosInstance.get("/api/submissions");

        setStats({
          totalDevices: devices.data.length,
          totalChannels: channelsResponse.data.length,
          totalSubmissions: submissionsResponse.data.length,
        });

        setChannels(
          channelsResponse.data.map((channel) => ({
            ...channel,
            submissions: submissionsResponse.data.filter(
              (submission) => submission.channelName === channel.name
            ).length,
          }))
        );
        // const trends = submissionsResponse.data.reduce((acc, sub) => {
        //   const month = moment(sub.createdAt).format("MMM YYYY");
        //   acc[month] = (acc[month] || 0) + 1;
        //   return acc;
        // }, {});
        // setSubmissionTrends(trends);
      } catch (err) {
        console.error("Error fetching stats or channels", err);
      }
    };

    fetchStatsAndChannels();
  }, []);

  // // Chart data for devices distribution
  // const deviceDistributionData = {
  //   labels: channels.map((channel) => channel.name),
  //   datasets: [
  //     {
  //       label: "Submissions",
  //       data: channels.map((channel) => channel.submissions),
  //       backgroundColor: [
  //         "#3B82F6",
  //         "#10B981",
  //         "#F59E0B",
  //         "#EF4444",
  //         "#8B5CF6",
  //       ],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };

  // // Chart data for submission trends
  // const submissionTrendsData = {
  //   labels: Object.keys(submissionTrends),
  //   datasets: [
  //     {
  //       label: "Submissions",
  //       data: Object.values(submissionTrends),
  //       backgroundColor: "#3B82F6",
  //       borderColor: "#1E3A8A",
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // Fetch submissions for a specific channel
  const handleViewSubmissions = async (channel) => {
    try {
      const response = await axiosInstance.get(
        `/api/channel-submissions/${channel._id}`
      );
      const sortedData = response.data.submissions.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setSelectedChannel(channel);
      setSubmissions(sortedData);
      setFilteredSubmissions(sortedData);
    } catch (err) {
      console.error("Error fetching submissions for channel", err);
    }
  };

  // Handle search filter
  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = submissions.filter((submission) =>
      submission.device.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredSubmissions(filtered);
    setCurrentPage(1);
  };

  // Pagination logic
  const paginate = (items, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize;
    return items.slice(startIndex, startIndex + pageSize);
  };

  const paginatedSubmissions = paginate(
    filteredSubmissions,
    currentPage,
    pageSize
  );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">
        Super Admin Dashboard
      </h2>

      <div className="grid grid-cols-3 gap-6 mb-6">
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-gray-700">
          <h3 className="text-xl font-semibold">Total Devices</h3>
          <p className="text-3xl font-bold text-blue-600">
            {stats.totalDevices}
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-gray-700">
          <h3 className="text-xl font-semibold">Total Channels</h3>
          <p className="text-3xl font-bold text-green-600">
            {stats.totalChannels}
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-gray-700">
          <h3 className="text-xl font-semibold">Total Submissions</h3>
          <p className="text-3xl font-bold text-black">
            {stats.totalSubmissions}
          </p>
        </div>
      </div>

      {/* <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-xl font-bold text-gray-800 mb-4">
            Submission Trends
          </h3>
          <Bar data={submissionTrendsData} options={{ responsive: true }} />
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-xl font-bold text-gray-800 mb-4">
            Device Distribution by Channel
          </h3>
          <Pie data={deviceDistributionData} options={{ responsive: true }} />
        </div>
      </div> */}

      {/* Channels Table */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-xl font-bold mb-4">Channels Overview</h3>
        <table className="w-full border-collapse border border-gray-300 shadow-md rounded-lg">
          <thead className="bg-gradient-to-r from-gray-700 to-gray-800 text-white">
            <tr>
              <th className="p-4 text-center font-semibold uppercase tracking-wide">
                API Key
              </th>
              <th className="p-4 text-center font-semibold uppercase tracking-wide">
                Name
              </th>
              <th className="p-4 text-center font-semibold uppercase tracking-wide">
                Status
              </th>
              <th className="p-4 text-center font-semibold uppercase tracking-wide">
                Submissions
              </th>
              <th className="p-4 text-center font-semibold uppercase tracking-wide">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {channels.map((channel) => (
              <tr
                key={channel._id}
                className="border-t text-center hover:bg-gray-100 transition"
              >
                <td className="p-4">{channel.apiKey}</td>
                <td className="p-4">{channel.name}</td>
                <td className="p-4">
                  <span
                    className={`px-3 py-1 rounded-full text-sm ${
                      channel.status === "Online"
                        ? "bg-green-100 text-green-700"
                        : "bg-red-100 text-red-700"
                    }`}
                  >
                    {channel.status}
                  </span>
                </td>
                <td className="p-4">{channel.submissions}</td>
                <td className="p-4">
                  <button
                    onClick={() => handleViewSubmissions(channel)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Submissions Table with Pagination and Search */}
      {selectedChannel && (
        <div className="bg-white shadow-md rounded-lg p-6 mt-6">
          <h3 className="text-xl font-bold mb-4">
            Submissions for Channel: {selectedChannel.name}
          </h3>

          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search by device..."
            className="w-full p-2 border rounded mb-4"
          />

          {paginatedSubmissions.length > 0 ? (
            <table className="w-full border-collapse border border-gray-300 shadow-md rounded-lg">
              <thead className="bg-gradient-to-r from-gray-700 to-gray-800 text-white">
                <tr>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Device
                  </th>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Base Price
                  </th>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Estimated Trade-In Value
                  </th>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Date
                  </th>
                  <th className="p-4 text-center font-semibold uppercase tracking-wide">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedSubmissions.map((submission) => (
                  <tr key={submission._id} className="border-t">
                    <td className="p-4 text-center">{submission.device}</td>
                    <td className="p-4 text-center">
                      Rs. {submission.basePrice.toLocaleString()}
                    </td>
                    <td className="p-4 text-center">
                      Rs. {submission.finalTradeInValue.toLocaleString()}
                    </td>
                    <td className="p-4 text-center">
                      {moment(submission.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td className="p-4 text-center">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
                        onClick={() => setSelectedSubmission(submission)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-500 text-center">No submissions found.</p>
          )}

          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-300 px-4 py-2 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of{" "}
              {Math.ceil(filteredSubmissions.length / pageSize)}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) =>
                  Math.min(
                    prev + 1,
                    Math.ceil(filteredSubmissions.length / pageSize)
                  )
                )
              }
              disabled={
                currentPage === Math.ceil(filteredSubmissions.length / pageSize)
              }
              className="bg-gray-300 px-4 py-2 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {/* Submission Details Modal */}
      {selectedSubmission && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h3 className="text-lg font-semibold mb-4">
              Details for: {selectedSubmission.device}
            </h3>
            <ul className="list-disc pl-6">
              <li>
                <strong>Channel:</strong> {selectedSubmission.channelName}
              </li>
              <li>
                <strong>IMEI:</strong> {selectedSubmission.options.imei}
              </li>
              <li>
                <strong>Base Price:</strong> Rs. {selectedSubmission.basePrice}
              </li>
              <li>
                <strong>Estimated Device Value:</strong> Rs.{" "}
                {selectedSubmission.tradeInValue.toLocaleString()}
              </li>
              <li>
                <strong>Trade-In Bonus:</strong> Rs.{" "}
                {selectedSubmission.tradeInBonus.toLocaleString() || 0}
              </li>
              <li>
                <strong>Estimated Trade-In Value:</strong> Rs.{" "}
                {selectedSubmission.finalTradeInValue.toLocaleString() || 0}
              </li>
              <li>
                <strong>Battery Health:</strong>{" "}
                {selectedSubmission.options.batteryHealth || "N/A"}
              </li>
              <li>
                <strong>Storage:</strong>{" "}
                {selectedSubmission.options.storageSize || "N/A"}
              </li>
              <li>
                <strong>Color:</strong>{" "}
                {selectedSubmission.options.color || "N/A"}
              </li>
              <li>
                <strong>SIM Variant:</strong>{" "}
                {selectedSubmission.options.simVariant || "N/A"}
              </li>
              <li>
                <strong>Functional:</strong>{" "}
                {selectedSubmission.options.isFunctional.includes("Yes")
                  ? "Yes"
                  : "No"}
              </li>
              {selectedSubmission.options.isFunctionalDetails?.length > 0 && (
                <li>
                  <strong>Functional Issues:</strong>{" "}
                  {selectedSubmission.options.isFunctionalDetails.join(", ")}
                </li>
              )}
              <li>
                <strong>Damaged:</strong>{" "}
                {selectedSubmission.options.isDamaged.includes("Yes")
                  ? "Yes"
                  : "No"}
              </li>
              {selectedSubmission.options.isDamagedDetails?.length > 0 && (
                <li>
                  <strong>Damage Details:</strong>{" "}
                  {selectedSubmission.options.isDamagedDetails.join(", ")}
                </li>
              )}
              <li>
                <strong>Repaired:</strong>{" "}
                {selectedSubmission.options.isRepaired.includes("Yes")
                  ? "Yes"
                  : "No"}
              </li>
              {selectedSubmission.options.isRepairedDetails?.length > 0 && (
                <li>
                  <strong>Repair Details:</strong>{" "}
                  {selectedSubmission.options.isRepairedDetails.join(", ")}
                </li>
              )}
              <li>
                <strong>Cosmetic Condition:</strong>
                <ul className="list-none pl-6">
                  <li>
                    <strong>Display:</strong>{" "}
                    {selectedSubmission.options.cosmeticCondition?.display ||
                      "N/A"}
                  </li>
                  <li>
                    <strong>Back:</strong>{" "}
                    {selectedSubmission.options.cosmeticCondition?.back ||
                      "N/A"}
                  </li>
                  <li>
                    <strong>Sides:</strong>{" "}
                    {selectedSubmission.options.cosmeticCondition?.sides ||
                      "N/A"}
                  </li>
                </ul>
              </li>
              <li>
                <strong>Factory Unlocked:</strong>{" "}
                {selectedSubmission.options.factoryUnlocked || "N/A"}
              </li>
              <li>
                <strong>Accessories:</strong>{" "}
                {selectedSubmission.options.accessories || "N/A"}
              </li>
              <li>
                <strong>Discount Code:</strong>{" "}
                {selectedSubmission.discountCode || "None"}
              </li>
              <li>
                <strong>Submission Date:</strong>{" "}
                {moment(selectedSubmission.createdAt).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </li>
            </ul>
            <button
              onClick={() => setSelectedSubmission(null)}
              className="bg-red-500 text-white px-4 py-2 rounded mt-4"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
